.footerSection{
    position:relative;
    display: flex;
    align-items: end;
    justify-content: center;
    overflow: hidden;
    padding: 9.1146vw 0 2.6042vw; // 175 0 50

    .contact-list{
        display: flex;
        justify-content: flex-end;
        overflow: hidden;
        height: 6.2500vw; // 120
        
        a{
            width: 22.9167vw; // 440
            font-size: 1.4583vw; // 28
            letter-spacing: 0.5208vw; // 10
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0.0521vw solid #fff;
            position:relative;

            &:hover{
                &{
                    color: #000;
                }
                &:last-child::before{
                    visibility: visible;
                    width: 100%;
                }

                &:first-child::before{
                    visibility: visible;
                    width: 100%;
                }
                &::before{
                    visibility: visible;
                    height: 100%;
                }
            }
            
            &:last-child::before{
                width:0%;
                height:100%;
                left: initial;
                right:0;
            }

            &:first-child::before{
                width:0%;
                height:100%;
            }

            &::before{
                z-index: -1;
                visibility: hidden;
                width: 100%;
                height: 0%;
                position: absolute;
                left:0;
                top: 0;
                background: #fff;
                transition: .3s;
                @include virtual_element_block;
            }
        }
    }

    .typoGraphy{
        margin-top: 2.0833vw; // 40
        display: flex;
        flex-direction: column;

        .typeBox{
            display: flex;
            justify-content: end;

            &:nth-child(2){
                justify-content: space-between;
                align-items: center;
            }

            p{
                font-size: 13.4375vw; // 258
                line-height: 14.4792vw; // 278
                font-weight: bold;
                @include engFont;

                span{
                    display:inline-flex;
                    align-items: center;
                }
            }

            .message{
                width: 12.5000vw; // 240
                height: 12.5000vw; // 240
                margin-left: 1.2083vw; // 100
                font-size: 1.4583vw; //28
                display: flex;
                justify-content: center;
                align-items: center;
                border: 0.0521vw solid #fff;
                border-radius: 50%;
                overflow: hidden;

                &:hover{
                    color: #000;

                    &::before{
                        visibility: visible;
                        height: 100%;
                    }
                }

                &::before{
                    z-index: -1;
                    visibility: hidden;
                    width: 100%;
                    height: 0%;
                    position: absolute;
                    left:0;
                    top: 0;
                    background: #fff;
                    transition: .3s;
                    @include virtual_element_block;
                }
            }
        }
    }
}


@include tablet{
    .footerSection{
        padding: 11.7188vw 0 3.1250vw; // 175 0 50

        .contact-list{
            height: 6.5938vw; // 120
            
            a{
                width: 33.33333334%; // 440
                font-size: 2.0313vw; // 28
                letter-spacing: 0.7031vw; // 10
                border: 0.0781vw solid #fff;
            }
        }
    
        .typoGraphy{
            margin-top: 2.7344vw; // 40
            .typeBox{
                p{
                    font-size: 12.7500vw; // 258
                    line-height: 14.2969vw; // 278
                }
    
                .message{
                    width: 13.1875vw; // 240
                    height: 13.1875vw; // 240
                    margin-left: 1.375vw; // 100
                    font-size: 1.6313vw; // 28
                }
            }
        }
    }
}



@include mobile{
    .footerSection{
        padding: 19.5313vw 0 6.5104vw; // 175 0 50

        .contact-list{
            height: 11.7188vw; // 120
            flex-wrap: wrap;
            
            a{
                width: 28.6458vw; // 440
                font-size: 3.1250vw; // 28
                letter-spacing: 0.2604vw; // 10
                border: 0.1302vw solid #fff;
            }
        }
    
        .typoGraphy{
            margin-top: 5.2083vw; // 40
            .typeBox{
                p{
                    font-size: 18.2292vw; // 258
                    line-height: 20.8333vw; // 278
                }
    
                .message{
                    width: 16.9271vw; // 240
                    height: 16.9271vw; // 240
                    margin-left: 0; // 100
                    font-size: 3.1250vw; // 28
                }
            }
        }
    }
}