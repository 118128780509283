@import './reset';
@import './font';
@import './header';
@import './footer';
@import './mixin';
@import './intro';
@import './about';
@import './project';
@import './highlight';

body {
    background: #000 linear-gradient(360deg, rgba(0,0,0,1) 26%, rgba(17, 206, 130, 0.16) 70%, rgba(0, 0, 0, 0.2) 100%);
    overflow-x: hidden;
}

.wrap {
    @include wrapPadding;
}

.visible_mobile {
    @include visible_mobile;
}

.visible_desktop {
    @include visible_desktop;
}

.visible_mobile_tablet{
    @include visible_mobile_tablet;
}

.visible_desktop_only{
    @include visible_desktop_only;
}

input, textarea {
    background: none;
}

.blind{
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(0,0,0,0);
    overflow: hidden;
}


.cursor {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    pointer-events: none;
    transition: transform 0.2s ease-out; /* 스무스한 위치 이동을 위한 transition */
    transform: translate(-50%, -50%); /* 커서의 중심을 기준으로 맞춤 */
    
    &.clicked::before {
        width: 15px;
        height: 15px;
        background-color: #2EE52B;
    }

    &::before {
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #fff;
        backdrop-filter: invert(80%);
        opacity: 0.7;
        transition: background-color 0.2s, width .2s, height .2s ; /* 클릭 시 배경색 변화 */
    }
}

@include mobile{
    .cursor{
        display:none;
    }
}