.pin-spacer{
    padding:0 !important;
}
.highlightSection{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;

    .content{
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    
        .bgBox{
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            width: 100vw;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: .5;
            overflow:hidden;
            video{
                width: 100%;
                -o-object-fit: cover;
                object-fit: cover;
            }
        }
        .textBox{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            font-size: 5.7292vw; // 110
            line-height: 6.7708vw; // 130
            font-weight: bold;
            p{
                @include engFont;
            }
        }
        .bottomTextBox{
            position: absolute;
            bottom: 2.6042vw; // 50
            width: 100%;
            text-align: center;

            p{
                font-size: 1.7708vw; // 34
                line-height: 2.2917vw; // 44
            }
        }
    }
}

@include tablet{
    .highlightSection{
        .textBox{
            font-size: 7.8125vw !important; // 110
            line-height: 9.3750vw !important; // 130
        }

        .bottomTextBox{
            bottom: 4.6875vw !important; // 50

            p{
                font-size: 2.3438vw !important; // 34
                line-height: 3.1250vw !important; // 44
            }
        }
    }
}

@include mobile{
    .highlightSection{
        .textBox{
            font-size: 10.4167vw !important; // 110
            line-height: 13.0208vw !important; // 130
        }

        .bottomTextBox{
            bottom: 7.8125vw !important; // 50

            p{
                font-size: 3.9063vw !important; // 34
                line-height: 5.2083vw !important; // 44
            }
        }
    }
}